import * as React from 'react';

import Meta from '../components/meta';
import SecondaryPageLayout from '../components/secondary-page-layout';
import { Url } from '../url';
import { SubscribeForMajorUpdatesPageTitle } from '../localisation';

import * as classes from './subscribe-for-major-updates.module.css';

function SubscribeForMajorUpdates() {
  return (
    <SecondaryPageLayout>
      <Meta
        title={SubscribeForMajorUpdatesPageTitle}
        url={Url.SubscribeForMajorUpdates}
      />

      <iframe
        src="https://cdn.forms-content.sg-form.com/9f9b018f-1360-11ed-9aa8-269dce37a2e7"
        className={classes.iframe}
      />

    </SecondaryPageLayout>
  );
}

export default SubscribeForMajorUpdates;
